.Banner {
    height: 500px;
    width: 100%;
    object-fit: cover;
    margin-top: 30px;
    margin-bottom: 15px;
    filter: brightness(1.1);
    
}  

@media screen and (max-width: 768px) {
    
    .Banner{
      width: 100%;
      height: auto;
    }
    @media screen and (max-width: 768px) {
    
  
      .bannerTitle h1 {
          font-size: 20px;
        }
    
     
        
      
    }
    }
    
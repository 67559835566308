/* DÉBUT: conteneur d'affichage des cartes logements */
.homecard-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    margin-bottom: 40px;
    padding: 50px;
  }
  
  .link {
    text-decoration: none;
  }
  
  /* FIN: conteneur d'affichage des cartes logements*/
  /* DÉBUT: carte logement*/
  .card {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 0; /* Ajustez la hauteur ici */
  }
  
  .card img {
    width: 100%;
    border-radius: 10px;
    height: auto;
    object-fit: cover;
    filter: brightness(1.02);
  }
  
  .card h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-family: 'Porky\'s';
    letter-spacing: 0.8px;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 142.6%;
    color: white;
    z-index: 1; /* Assurer que le texte est au-dessus de l'image */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); 
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px #473030;
    cursor: pointer;
  }
  /* FIN: carte logement */
  
  @media screen and (max-width: 730px) {
  
    .homecard-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      
    }
  
    
  }
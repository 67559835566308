.Navigation {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}


.mobile-menu-icon {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

.MobileNavigation {
    list-style: none;
    padding: 0;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    display: none;
    z-index: 1000;
}

.MobileNavigation.active {
    display: block;

}

.fermer-menu {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}


@media screen and (max-width: 730px) {
    .NavigationItem {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
        color: #016fd0;
        font-size: 40px;

    }
    

    .MobileNavigation {
        text-align: center;
    }

    .MobileNavigation li {
        margin: 10px 0;
        text-align: center;
        text-decoration: none;
        color: #000;
        font-family: Raleway;
        text-transform: uppercase;
        font-size: 18px;
        display: block;
        padding: 10px;
    }

    .MobileNavigation a {
        text-decoration: none;
        color: #016fd0;
        font-size: 18px;
        display: block;
        padding: 10px;
    }

    .MobileNavigation.active {
        display: block !important;
    }
}
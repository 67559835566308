.category-section {
    
    border-radius: 10px;
    height: 60px;
    padding: 6px;
    width: 65%;
    margin: 40px auto 1rem;
  }
  
  .category-filter {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .category-item {
    padding: 8px 16px;
    font-size: 20px;
    border: 1px solid #fff;
    background-color: #fff; /* Fond blanc par défaut */
    cursor: pointer;
    transition: all 0.5s ease; /* Animation de transition */
    border-radius: 6px
  }
  
  .category-item.active {
    background-color: #FFAC00; /* Fond rouge pour la catégorie sélectionnée */
    color: #fff; /* Texte blanc pour la catégorie sélectionnée */
    border: 1px solid #FFAC00;
    font-weight: 600;
  }
  
  @media screen and (max-width: 768px) {
    .category-item{
      font-size: 14px;
    }
  
   
  }
  @media screen and (max-width: 730px) {
    .category-item{
      font-size: 14px;
    }
  
    .category-section {
    
    
      width: 90%;
      margin: -20px auto 1rem;
      
    }
  }
.footer {
    position: relative;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: "devyoupikids/src/Assets/Footer.png";
    color: var(--colorWhite);
    font-size: 24px;
}



footer-img {
    margin: 0 auto;
    width: 100%;
    height: 50px;
    text-align: center;

}


.footer-img img {

    width: 100%;
    height: auto;

}




.footer-text {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    width: 100%;
    
}

.footer-text p {
    margin-top: 10px;
    text-shadow:2px 2px 4px rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 730px) {
    .footer-text {
       
       
        font-size: 18px;
        
        
    }
}

.birthday-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  
  margin-top: 20px;
  
  margin-left: 40px;
  margin-right: 40px;
}
.modal-open {
  overflow: hidden;
}
.b-modal-personalised {
  
  background-color: white;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto; 
  max-width: 500px; 
  margin: 50px auto; 
  z-index: 10000 !important;
}
.b-modal-content {
  padding: 40px;
  height: auto;
  text-align: center; 
  z-index: 9999!important;
}

.modal-title {
  
  text-align: center;
}

.b-modal-imge {
  height: auto;
  max-width: 50%;
  border-radius: 15px;
  display: block;
  margin: 0 auto;
}

.b-close-button {
  /* Styles pour le bouton de fermeture (croix) */
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 50px;
  cursor: pointer;
  color: #666;
}

.b-modal-content h2.centered-heading {
  text-align: center;
  font-family: "porky's";
  letter-spacing: 1.5px;
  margin-top: 0;
}



.b-modal-content ul {
  text-align: left; 
  padding-left: 0; 
  list-style-type: circle; 
}

.b-modal-content li {
  text-align: left; 
  padding-bottom: 15px;
  
}
.anniversaire-titre{
  font-family: "porky's";
  letter-spacing: 1.5px;
  font-size: 20px;
 text-decoration:underline;
 color: #0171D2 ;

}

.birthday-bannerTitle {
  z-index: 1;
}
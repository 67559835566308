.activity-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  /* Espace entre les cartes */
  margin-top: 20px;
  /* Marge en haut */
  margin-left: 40px;
  margin-right: 40px;

}

.section-background {
  padding: 20px 20px 70px 20px;
  background-color: #E2F3F9;

}

.overlay-class {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
body.modal-open {
  overflow: hidden;
}

.modal-personalised {
  background-color: white;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.4px solid grey;
  overflow-y: auto; /* Essayez 'auto' au lieu de 'scroll' */
  max-height: calc(100% - 20px);
}

.modal-content {
  padding: 40px;
  overflow-y: auto;
  height: auto;
  text-align: center;
}

.modal-title {
  color: #FFAC00;
  font-family: "porky's";
  letter-spacing: 1.5px;
  text-align: center;
  font-size: 32px;
}

.modal-image {
  height: 300px;
  width: 70% !important;
  border-radius: 15px;
  display: block;
  margin: 0 auto;
}

.close-button {

  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 50px;
  cursor: pointer;
  color: #666;
}

.modal-open {
  overflow: hidden;
}

.category-name {
  font-size: 24px;
  margin-bottom: 1rem;
  font-weight: 600;
}

.modal-content p {
  font-size: 18px;
  line-height: 28px;
  padding: 0 3rem;

}

@media screen and (max-width: 768px) {
  .close-button {
    top: 10px;
    right: 30px;
  }



}

@media screen and (max-width: 730px) {
  .activity-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .close-button {
    top: 10px;
    right: 30px;
  }

}

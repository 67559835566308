.birthday-page {
    display: flex;
    justify-content: center; /* Centre les cartes horizontalement */
    padding: 80px;
  }
  
  .card-container {
    display: flex;
    flex-wrap: nowrap; /* Empêche le passage à la ligne */
    gap: 20px; /* Espace entre les cartes */
    max-width: 100%; /* Ajuste la largeur maximale */
  }
  
.birthday-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .birthday-card:hover {
    transform: translateY(-5px);
  }
  
  .image-container {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }
  
  .image-container img {
    width: 100%;
    display: block;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-content h3 {
    margin-top: 0;
    font-size: 1.2em;
  }
  
  .card-content p {
    margin-top: 0;
    color: #666;
  }
  .card-picture{
    width: 100%;
    height: 200px;
  }

  .birthday-button {
    font-size: 18px;
    font-weight: 700;
    background-color: #E1016E;
    border: #E1016E solid;
    color: #fff;
    border-radius: 10px 10px 10px 10px;
    padding: 8px;
    margin-bottom: 15px;


}

.birthday-button:hover {
  cursor: pointer;
}
.card-body{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 15px;
  align-items: flex-start; 
}

.card-body h3{
  font-family: "porky's";
  font-size: 22px;
  letter-spacing: 1.5px;
}

.card-body p{
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
    
  
  .birthday-page {
    
    padding: 0 0 50px 0;
  }

  .card-body h3 {
    
    font-size: 16px;
  }
    
    .card-picture {
      width: 100%;
      height: 100px;
    }
    .card-body p {
      font-size: 11px;
      
  }
  .birthday-button {
    font-size: 11px;
    
}
}

@media screen and (max-width: 730px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 100%;
  }
  .birthday-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    width: 100%; 
    max-width: 400px;
  }
}

@media screen and (max-width: 320px) {
  .card-container {
   
    grid-template-columns: repeat(1, 1fr);
    
  }
  
}
.lil-smash-burger-section {
  display: flex;

  text-align: center;
  padding: 50px 0;

}


.left-content {

  margin-right: 20px;
  position: relative;
  overflow: hidden;
}

.left-content img {
  width: 60%;
  max-width: 600px;
  height: auto;
}

.right-content hr {
  margin: 4% 0;
  color: black;
  width: 60%;
}


.right-content {
  flex: 1;
}

.lil-smash-burger-section img {
  width: 70%;
  max-width: 600px;
  height: 100%;
  margin-bottom: 20px;
}

.lil-smash-burger-section p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.leftie {
  text-align: left;
  padding-top: 50px;

}

.slick-prev:hover {
  background-color: #FF8B05;
}

.slick-prev:active {
  background-color: #FF8B05;
}

.slick-next:hover {
  background-color: #FF8B05;
}

.slick-next:active {
  background-color: #FF8B05;
}

.slick-prev,
.slick-next {
  background-color: #FF8B05;
}

.banner-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.heading-content {
  display: flex;
  justify-content: center;

}

.subtitle {
  color: #FF8B05;
}

.heading-content2 {
  display: flex;
  justify-content: center;
  margin: 0 53px 53px 53px;
}

.subtitle2 {
  color: #F15A35;
}



.resto-bannerTitle h1 {
  color: white;
  font-family: 'Porky\'s';
  font-size: 50px;
  letter-spacing: 1px;
  margin: 0;
  text-shadow: 0px 3px 6px #0000004D;
  position: absolute;
  text-align: center;
  z-index: 1;
  margin-top: -360px;
  width: 100%;

}

.resto-bannerTitle p {
  color: white;
  font-size: 24px;
  font-weight: 500;
  font-family: "Raleway";
  margin: 0;
  letter-spacing: -0.6px;
  text-shadow: 0px 3px 6px #00000080;
  opacity: 1
}

.youpisweety-section {
  display: flex;
  text-align: center;
  padding-top: 115px;
  margin-left: 70px;
  padding-bottom: 50px;
}

.youpisweety-section img {
  width: 70%;
  max-width: 600px;
  height: 100%;
  margin-bottom: 20px;
}

.youpisweety-section p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  max-width: 800px;
  /* Ajustez la largeur maximale du texte selon vos besoins */
  margin: 0 auto;
  text-align: left;
}

.menu-section {
  padding-top: 60px;
}

.menu-section2 {
  background-color: #FADED8;
}

@media screen and (max-width: 768px) {
  .lil-smash-burger-section img {
    height: auto;
  }

  .lil-smash-burger-section img {
    width: 80%;
  }

  .right-content {
    flex: auto;
  }

  .youpisweety-section img {
    width: 100%;
    height: 50%;
  }

  .menu-card h3 {
    font-size: 14px;
  }

  .resto-bannerTitle {
    font-size: 20px;
  }

}

/* Styles pour écrans jusqu'à 730 pixels de large */
@media screen and (max-width: 730px) {


  .lil-smash-burger-section {
    display: block;
    text-align: center;
    padding: 20px;
  }

  .lil-smash-burger-section img {
    width: 100%;
    max-width: none;
    height: auto;
    margin-bottom: 20px;
  }

  .right-content {
    padding-left: 0;
    padding-top: 60px;
  }

  .youpisweety-section {
    display: block;
    text-align: center;
    padding: 20px;
  }

  .youpisweety-section img {
    width: 100%;
    max-width: none;
    height: auto;
    margin-bottom: 20px;
  }

  .leftie {
    padding-top: 0;
  }
  .resto-bannerTitle h1  {
    font-size: 30px;
    margin-top: -185px;
    
  }
}
.activity-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer; 
    box-shadow: 0px 16px 40px #00000030;

}

.activity-image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease-in-out;
}

.activity-card:hover .activity-image {
    transform: scale(1.1);
}


.activity-info {
    padding: 20px;
}

.activity-title {
    margin: 0;
    font-size: 20px;
    color: #FFAC00;
    font-family: "porky's";
    letter-spacing: 1.5px;
    text-align: left;
    
}

.activity-description {
    margin: 10px 0 0;
    font-size: 1.2rem;
    color: #333;
}

@media screen and (max-width: 768px) {
    .activity-title{
      font-size: 12px;
    }
    .activity-image{
        height: 125px;
    }
  
   
  }
.menu-card {
  border: 1px;
  padding: 15px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
}

.menu-card img {
  height: clamp(185px, 13.28125vw, 255px);
  min-height: clamp(185px, 13.28125vw, 255px);
  display: block;
  /* Assurez-vous que l'image est un bloc pour appliquer les marges automatiques */
  margin: 0 auto;
  /* Marge automatique à gauche et à droite pour centrer horizontalement */

}

.menu-card h3 {
  font-family: "porky's";
  letter-spacing: 1px;
  color: #FF8B05;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
@media screen and (max-width: 768px) {
  .menu-card img {
    /* Ajustez la taille en conséquence pour la version tablette */
    height: clamp(100px, 8vw, 150px);
    min-height: clamp(100px, 8vw, 150px);
  }
}

@media screen and (max-width: 730px) {
  .menu-card img {
    width: 100%; 
    height: 100%;
    object-fit: cover;
  }
  .menu-card {
    font-size: 13px;
  }
}
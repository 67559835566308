.group-text {
  font-size: 24px;
  font-weight: 500;
  font-family: "Raleway";
}






.group-bannerTitle {
  position: absolute;
  text-align: center;
  z-index: 1;
  margin-top: -360px;
  width: 100%;


}

.group-bannerTitle h1 {
  color: white;
  font-family: 'Porky\'s';
  font-size: 50px;
  letter-spacing: 1px;
  margin: 0;
  text-shadow: 0px 3px 6px #0000004D;

}


@media screen and (max-width: 768px) {
    
  
  .group-bannerTitle h1 {
      font-size: 40px;
    }

 
    
  
}
@media screen and (max-width: 730px) {
  

  

    .group-bannerTitle   {
      font-size: 16px;
      margin-top: -185px;
  
    }
    
  
    .group-text {
      font-size: 18px;
      margin: 20px;
  }
  
}
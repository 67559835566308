/* Styles pour la section horaires */
.horaires-container {
  background-color: white;
  padding-top: 40px;
}

/* Styles pour la grille des horaires */
.horaires-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

/* Styles pour chaque colonne */
.periode h3 {
  margin-bottom: 30px;
}
.intro{
  margin:40px;
}
.jours {
  color: #0171D2;
  font-weight: 900;

}

.heure {
  margin-bottom: 30px;
}

.periode {
  border-right: 1px solid #ccc;
  /* Ajoute une bordure à droite */
  padding-right: 20px;
  /* Espacement entre la bordure et le contenu */
  margin-right: 20px;
  /* Espacement entre les colonnes */
}

/* Dernière colonne n'aura pas de bordure */
.periode:last-child {
  border-right: none;
}
.horaires-container h2{
  font-family: 'Porky\'s';
  font-size: 40px;
  letter-spacing: 1px;
  color: #0171D2;
  margin: 10px;
}

@media screen and (max-width: 730px) {
 
  .horaires-grid {
    
    grid-template-columns: repeat(1, 1fr);
    
  }
  .periode {
    border: none;
    margin: 0;
}
}